import { drupal } from "lib/drupal";
import { getParams } from "lib/getParams";
import Layout from "components/templates/Layout";
import NodeInmueble from "components/organisms/NodeInmueble";
import NodeLandingPage from "components/organisms/NodeLandingPage";
import { getBlockMainMenu, getBlockFooterMenu } from "lib/getGlobalElements";
import { truncateText,  absoluteUrlNext } from "../lib/utils";
import getBlurURL from '../lib/getBlurURL';
import {
  transformMainFooterMenu,
  transformRecentInmuebles, transformResource,
} from '../lib/transform';

const RESOURCE_TYPES = [
  // "node--article",
  "node--inmueble",
  // "node--agency",
  "node--landing_page"
];
/**
 * @param nodes
 * @returns {JSX.Element}
 */
export default function NodePage({
  resource,
  articles,
  inmueblesView,
  mainMenu,
  footerMenu,
  metaData
}) {

  //validation schema
  const currency = resource.field_currency_iso || 'MXN';
  const price = resource.field_price || '0';
  const isHome = resource.path?.alias === '/home';

  return (
    <Layout
      title={metaData.metaTitle + " | Tu Casa Mi Casa"}
      description={metaData.metaDescription}
      actions={metaData.keyTipoTrato}
      inmueble={metaData.isInmueble}
      name={metaData.metaTitle}
      descripProperty={metaData.metaDescription}
      url={`${absoluteUrlNext(resource.path.alias)}`}
      price={price}
      currency={currency}
      mainMenu={mainMenu}
      footerMenu={footerMenu}
      isHome={isHome}
      keywords={metaData.keywords}
    >
      {resource.type === "node--landing_page" && (
        <NodeLandingPage node={resource} inmuebles={inmueblesView} />
      )}
      {/*/!*{resource.type === "node--article" && (*!/*/}
      {/*/!*  <NodeArticle node={resource} articles={articles} />*!/*/}
      {/*/!*)}*!/*/}
      {resource.type === "node--inmueble" && <NodeInmueble node={resource} />}
      {/*/!*{resource.type === "node--agency" && (*!/*/}
      {/*/!*  <h1>Página agencia en construcción</h1>*!/*/}
      {/*/!*)}*!/*/}
    </Layout>
  );
}

export async function getStaticPaths(context) {
  const paths = await drupal.getStaticPathsFromContext(RESOURCE_TYPES, context);
  const excludePaths = ["/", "/es", ""];
  const newPaths = paths.filter(
    (path) => !excludePaths.includes(path.params.slug[0])
  );
  return {
    paths: newPaths,
    fallback: "blocking",
  };
}

export async function getStaticProps(context) {
  const path = await drupal.translatePathFromContext(context);

  // If path is not found or the resource is not one we care about, return 404
  if (!path || !RESOURCE_TYPES.includes(path.jsonapi.resourceName)) return {
    notFound: true,
  }

  const type = path.jsonapi.resourceName;
  const resource = await drupal.getResourceFromContext(path, context, {
    params: getParams(type).getQueryObject(),
  });

  // At this point, we know the path exists.
  // If we receive an error, it means something went wrong on Drupal.
  // We throw an error to tell revalidation to skip this for now.
  // Revalidation can try again on next request.
  if (!resource) {
    throw new Error(`Failed to fetch resource: ${path.jsonapi.individual}`);
  }

  // If we're not in preview mode and the resource is not published, return page not found.
  if (!context.preview && resource?.status === false) return {
    notFound: true,
  };

  let recentArticles = null;
  if(type === "node--article") {
    recentArticles = await drupal.getView("articulos_recientes--block_1", {
      params: getParams("node--article", "view").getQueryObject(),
    });
  }

  let recentInmuebles = null;
  if(type === "node--landing_page") {
    if(path.label === "Home") {
      recentInmuebles = await drupal.getView(
        "view_inmuebles_recientes--block_1",
        {
          params: getParams("node--inmueble", "view").getQueryObject(),
        }
      );
      if(recentInmuebles?.results) {
        recentInmuebles.results = recentInmuebles?.results.map( (result) => {
          if(result?.field_link_imagen?.length >= 1) {
            // Solo dejamos la primer imagen para no enviar los otros links y ocupar espacio.
            return { ...result, "field_link_imagen": [{ ...result?.field_link_imagen[0] }] }
          } else {
            return result;
          }
        });
      }
    }

    // Modificamos las imagenes que hay en field_elementos
    if(resource?.field_elementos.length > 0) {
      resource.field_elementos = resource.field_elementos.map( elemento => {
        switch (elemento.type) {
          case 'paragraph--banner':
            const linksKeysBanner = Object.keys(elemento.field_imagen_banner?.field_media_image?.links)
            linksKeysBanner.forEach( async linksKeysKey => {
              elemento.field_imagen_banner.field_media_image.links[linksKeysKey] = {
                ...elemento.field_imagen_banner.field_media_image.links[linksKeysKey],
                blurURL: await getBlurURL(elemento.field_imagen_banner.field_media_image.links[linksKeysKey.href])
              }
            });
            return elemento;
          case 'paragraph--imagen_texto_posicion':
            let linksKeysImagenTextoPosicion = Object.keys(elemento.field_imagen?.field_media_image?.links)
            linksKeysImagenTextoPosicion.forEach( async linksKeysKey => {
              elemento.field_imagen.field_media_image.links[linksKeysKey] = {
                ...elemento.field_imagen.field_media_image.links[linksKeysKey],
                blurURL: await getBlurURL(elemento.field_imagen.field_media_image.links[linksKeysKey.href])
              }
            });
            return elemento;
          default:
            return {
              ...elemento
            }
        }
      });
    }
  }

  const metaData = {
    metaTitle: resource.field_seo_title || resource.title,
    metaDescription: `Encuentra tu hogar ideal en nuestro portal. Amplia selección de propiedades y herramientas para simplificar tu búsqueda. ¡Descubre hoy tu nuevo hogar!`,
    clave: ``,
    keywords: ["casas", "propiedades", "venta", "renta", "inmobiliaria", "departamentos"].join(", "),
    isInmueble: false,
    keyTipoTrato: ""
  };
  if (resource.type === "node--inmueble") {
    const clave = resource.field_advertisement_id || "";
    const keyTitle = resource.field_seo_title || resource.title
    const keyTipoTrato = resource.field_treatement_type_id?.name?.toLocaleLowerCase() || "";
    const keyTipoPropiedad = resource.field_property_type_id?.name || "";
    const keyState = resource.field_state?.name || "";
    const keyMunicipality = resource.field_municipality?.name || "";
    const keyBedrooms = resource.field_bedrooms !== 0 && resource.field_bedrooms ? (resource.field_bedrooms>=2 ? resource.field_bedrooms + " recámaras":resource.field_bedrooms + " recámara") : "";
    const keyConstruct = resource.field_area_construction !== 0 && resource.field_area_construction ? (resource.field_area_construction + " metros de construcción") : "";
    const keyTerrain = resource.field_area_terrain !== 0 && resource.field_area_terrain ? (resource.field_area_terrain + " metros de terreno") : "";
    const keyRestrooms = resource.field_restrooms !== 0 && resource.field_restrooms ? (resource.field_restrooms>=2 ? resource.field_restrooms + " baños":resource.field_restrooms + " baño") : "";
    const keyToiletNum = resource.field_toilet_number !== 0 && resource.field_toilet_number ? (resource.field_toilet_number>=2 ? resource.field_toilet_number + " medios baños":resource.field_toilet_number + " medio baño") : "";
    const keyParkingSpaces = resource.field_parkingspaces !== 0 && resource.field_parkingspaces ? (resource.field_parkingspaces>=2 ? resource.field_parkingspaces + " estacionamientos":resource.field_parkingspaces + " estacionamiento") : "";
    const dynamicKeywords = [keyTipoTrato, keyTipoPropiedad, keyState, keyMunicipality, keyTitle, keyBedrooms, keyConstruct, keyTerrain, keyRestrooms, keyToiletNum, keyParkingSpaces].filter(Boolean);
    metaData['metaDescription'] = `${clave} ${truncateText(resource.body?.processed, 157)}`;
    metaData['keywords'] = dynamicKeywords.join(", ");
    metaData['isInmueble'] = true;
    metaData['keyTipoTrato'] = keyTipoTrato;
  } else if (resource.type === "node--landing_page") {
    metaData['metaDescription'] = resource.field_meta_description || metaData['metaDescription'];
    metaData['metaTitle'] = resource.field_meta_title || metaData['metaTitle'];
    if (resource.field_meta_keywords && resource.field_meta_keywords.length > 0)
      metaData['keywords'] = resource.field_meta_keywords.join(", ");
  }

  const mainMenu = transformMainFooterMenu(await getBlockMainMenu());
  const footerMenu = transformMainFooterMenu(await getBlockFooterMenu());
  const inmueblesView = transformRecentInmuebles(recentInmuebles?.results);
  const resourceTransformed = transformResource(resource);


  return {
    props: {
      resource: resourceTransformed,
      articles: recentArticles?.results || null,
      inmueblesView: inmueblesView || null,
      mainMenu,
      footerMenu,
      metaData
    },
    revalidate: 180,
  };
}
