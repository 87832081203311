import formatNodeImages from "lib/formatNodeImages";
import {useState, useEffect} from "react";
import dynamic from "next/dynamic";
import Text from "components/atoms/Text";
import Subtitle from "components/atoms/Subtitle";
import {useMediaQuery} from "@mui/material";
import CardInfoInmueble from "./CardInfoInmueble";
import { InView } from 'react-intersection-observer';
import { CircularProgress } from '@mui/material';
import Gallery from "components/molecules/Gallery"
import { Skeleton } from '@mui/material';

const DCustomIcon = dynamic(() => import("../atoms/CustomIcon"), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false,
});

const DynamicDivider = dynamic(() => import('@mui/material/Divider'),{ssr: false});
//
const OsmMapNoSSR = dynamic(() => import("./mapa/osmMap"), {loading: () => (
  <div className="flex justify-center m-3">
    <CircularProgress color="inherit" />
  </div>
),
ssr: false,
});

const FormEliminarInmueble = dynamic(() => import("./FormEliminarInmueble"), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false,
});

const ContactCard = dynamic(() => import("components/organisms/ContactCard"), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false,
});

const ModalCustom = dynamic(() => import("components/molecules/Modal/Modal"), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false,
});


//

function formatImages(node) {
  return formatNodeImages(node, "field_link_imagen", "field_imagenes", "1200");
}

export default function NodeInmueble({node}) {
  const laptop = useMediaQuery('(min-width:1024px)');
  let mensaje = "";

  let nodeIdentidades = node.field_contacto;
  let nodeIdentidadesObj = {
    image: {
      src: nodeIdentidades.field_imagen?.field_media_image?.links?.thumbnail.href || "#",
      alt:
        nodeIdentidades.field_imagen?.field_media_image.resourceIdObjMeta.alt ||
        "Default Alt",
    }
  };

  node["formattedImages"] = formatImages(node);
  const [location, setLocation] = useState({
    lat: node.field_ubicacion?.lon || 0.0,
    lng: node.field_ubicacion?.lat || 0.0,
  });
  //
  if (location.lat == 0.0 && location.lng == 0.0) {
    mensaje = " No se ha registrado una ubicacion ";
  }

  //ocultar precio
  let mostrarPrecio = "";
  let valor = "";
  if (node.field_ocultar_precio == false) {
    mostrarPrecio =
      "$" + node.field_price.toLocaleString("es-MX") || "Sin especificar";
    valor = node.field_currency_iso || "MXN" || "";
  }

  //ocultar ubicacion
  let mostrarCalle = "";
  let mostrarMapa = 0;
  if (node.field_ocultar_ubicacion == false) {
    mostrarCalle = node.field_street || " ";
    mostrarMapa = 1;
  }

  //ordenar direccion

  let colonia = node.field_colonie || "";
  let cp = node.field_postal_code?.name || "";
  let municipio = node.field_municipality?.name || "";
  let estado = node.field_state?.name || "";

  let direccion =
    mostrarCalle + " " + colonia + " " + cp + " " + municipio + " " + estado;

  //mostrar tipo de trato

  let tipoTrato =
    node.field_treatement_type_id?.resourceIdObjMeta
      .drupal_internal__target_id == 13
      ? "venta"
      : "renta";

  //remover html en body

  const formatText = (text) => {
      text = text.replace(/\./g, '. ');
      text = text.replace(/(<a\s+[^>]*href\s*=\s*['"][^'"]*['"][^>]*>)([^<]*)(<\/a>)/g, function(match, openTag, content, closeTag) {
        // Reemplaza los puntos dentro de la etiqueta <a href...>
        var modifiedContent = content.replace(/\. /g, '.');
        // Devuelve la etiqueta <a href...> modificada
        return openTag + modifiedContent + closeTag;
      });
        text = text.replace(/([a-z])([A-ZÁÉÍÓÚÑ])/g, '$1 $2');
        text = text.replace(/([A-Z])([A-Z])([a-z])/g, '$1 $2$3');
        text = text.replace(/([1-9])([A-ZÁÉÍÓÚÑ])/g, '$1 $2');
        text = text.replace(/([A-Z])(-)/g, '$1 $2');
        text = text.replace(/\)/g, ') ');
        text = text.replace(/\(/g, ' (');
        text = text.replace(/\:/g, ': ');
        text = text.replace(/(<a\s+[^>]*href\s*=\s*['"][^'"]*['"][^>]*>)([^<]*)(<\/a>)/g, function(match, openTag, content, closeTag) {
          // Reemplaza los puntos dentro de la etiqueta <a href...>
          var modifiedContent = content.replace(/\: /g, ':');
          // Devuelve la etiqueta <a href...> modificada
          return openTag + modifiedContent + closeTag;
      });
      text = text.replace(/\- /g, '</br>- ');
      text = text.replace("<pre data-placeholder=\"Traducción\" dir=\"ltr\">", '');
      text = text.replace("</pre>", '');
      text = text.replace("<strong>", '');
      text = text.replace("</strong>", '');
      text = text.replace(/\<strong.*?\>/g, '');
      text = text.replace(/\<h3.*?\>/g, '');
      text = text.replace(/\<h3.*?>/g, '');
      text = text.replace(/\<p.*?\>/g, '<p>');
      text = text.replace(/\<span.*?\>/g, '<span>');

      text = text.replace(">•", '•');
      text = text.replace(">>>", '>');
      text = text.replace(">>", '>');
      text = text.replace(/\-/g, '- ');
      text = text.replace(/\!/g, ' !');
      text = text.replace(/\¡/g, '¡ ');

      text = text.replace(/\<img.*?\>/g, '');
      // text = text.replace(/\<a href.*?\>/g, '');

      text = text.replace(/(>)( [a-z])/g, '$2');

      //Convirtiendo mayusuculas a minusculas
      const words = text.split(' ')
      const wordsCamel = words.map((word) => {
        if(/^[A-ZÁÉÍÓÚÑ.:,]+$/.test(word) || /^[A-ZÁÉÍÓÚÑ.:,]+/.test(word)){
          if(word.length === 1){
            return word.toLowerCase()
          }
          //Convierte la primera letra en mayúscula y el resto en minúsculas
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return word;
      });
      text = wordsCamel.join(' ')

      text = text.replace(/\ !/g, '!');
      text = text.replace(/\¡ /g, '¡');
      text = text.replace(/\- /g, '-');


    return text;
  }

  const description = formatText(node.body?.value);
  //superficie construcción
  const measureConst =
    node.field_unit_construction === "ha" ? (
      <span>ha</span>
    ) : (
      <span>
        m<sup>2</sup>
      </span>
    );

  //superficie terreno
  const measureTerrain =
    node.field_unit_terrain === "ha" ? (
      <span>ha</span>
    ) : (
      <span>
        m<sup>2</sup>
      </span>
    );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Remover guion
  const removerGuion = (numId) => {
    const numSinGuion = numId.replace("-", "");
    return numSinGuion;
  };

  

  const numIdAct = removerGuion(node.field_advertisement_id);
  const consNum = parseInt(numIdAct);

  //Loading
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); 
  }, []);

  return (
    <div className="2xl:max-w-[1920px] 2xl:m-auto">
      <section className="lg:mx-28 lg:flex xl:h-[400px] 2xl:h-[600px]">
        <div className="lg:w-2/5 mt-4 lg:mt-0 order-2">
          <CardInfoInmueble
            seoTitle={node.field_seo_title}
            title={node.title}
            cost={mostrarPrecio + " " + valor}
            address={direccion}
            bedrooms={node.field_bedrooms}
            areaConstruct={node.field_area_construction}
            measureConst={measureConst}
            areaTerrain={node.field_area_terrain}
            measureTerrain={measureTerrain}
            restrooms={node.field_restrooms}
            toiletNum={node.field_toilet_number}
            maintenance={node.field_maintenance}
            parkingspaces={node.field_parkingspaces}
            yearConst={node.field_year_construction}
            treatementType={tipoTrato}
          />
        </div>
        <div className="lg:w-3/5 order-1">
          {isLoading ? (
            <Skeleton
              animation="wave"
              varian="rect"
              sx={{
                height: "300px",
                "@media (min-width: 1024px)": {
                  height: "100%",
                },
              }}
              width="100%"
            />
          ) : (
            <Gallery images={node.formattedImages} />
          )}
        </div>
      </section>
      <div className="lg:flex lg:mx-28">
        <section
          role="region"
          aria-label="Informacion"
          className="lg:w-3/5 mt-14 lg:mr-12"
        >
          <div className="flex justify-between">
            {node.field_virtual_tour?.uri && (
              <div className="mt-5 mb-3 text-red font-bold hover:bg-gray-light p-2">
                <a
                  href={node?.field_virtual_tour?.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  Tour Virtual
                </a>
              </div>
            )}
          </div>
          <DynamicDivider />
          {!laptop && (
            <div className="flex lg:mt-14 lg:m-auto justify-center">
              <InView triggerOnce>
                {({ inView, ref, entry }) => (
                  <div ref={ref}>
                    <div className="hidden">{/*{ staticData() }*/}</div>
                    {inView && (
                      <ContactCard
                        name={nodeIdentidades.title}
                        urlImage={nodeIdentidadesObj.image.src}
                        propertyType={node.field_property_type_id.name}
                        id_propiedad={node.id}
                        nodeId={consNum}
                        claveProp={node.field_advertisement_id}
                      />
                    )}
                  </div>
                )}
              </InView>
            </div>
          )}
          {node.body?.value && (
            <div className="text-justify mt-5 mx-9 lg:mx-0 break-words">
              <Text text={description} />
            </div>
          )}
          <InView triggerOnce>
            {({ inView, ref, entry }) => (
              <div ref={ref}>
                <div className="hidden">{/*{ staticData() }*/}</div>
                {inView && (
                  <div>
                    <div className="text-center mt-10">
                      <Subtitle subtitle="CARACTERÍSTICAS" />
                    </div>
                    {node.field_general.length !== 0 ? (
                      <section className="my-5 mx-9">
                        <div className="font-bold mb-3">
                          <Text textColor="#000000" text="Generales:" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 mb-3">
                          {node.field_general.map((general) => {
                            return (
                              <span
                                className="text-lg flex my-3 lg:mr-4 text-base text-black"
                                key={general.id}
                              >
                                <DCustomIcon
                                  iconName="checkCircle"
                                  viewBox="0 0 40 40"
                                  className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                                />
                                {general.name}
                              </span>
                            );
                          })}
                        </div>
                        <DynamicDivider />
                      </section>
                    ) : (
                      ""
                    )}
                    {node.field_exterior.length !== 0 ? (
                      <section className="my-5 mx-9">
                        <div className="font-bold mb-3">
                          <Text textColor="#000000" text="Exterior:" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 mb-3">
                          {node.field_exterior.map((exterior) => {
                            return (
                              <span
                                className="text-lg flex my-3 mr-4 text-base text-black"
                                key={exterior.id}
                              >
                                <DCustomIcon
                                  iconName="checkCircle"
                                  viewBox="0 0 40 40"
                                  className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                                />
                                {exterior.name}
                              </span>
                            );
                          })}
                        </div>
                        <DynamicDivider />
                      </section>
                    ) : (
                      ""
                    )}
                    {node.field_politicas.length !== 0 ? (
                      <section className="my-5 mx-9">
                        <div className="font-bold mb-3">
                          <Text textColor="#000000" text="Políticas:" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 mb-3">
                          {node.field_politicas.map((politicas) => {
                            return (
                              <span
                                className="text-lg flex my-3 mr-4 text-base text-black"
                                key={politicas.id}
                              >
                                <DCustomIcon
                                  iconName="checkCircle"
                                  viewBox="0 0 40 40"
                                  className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                                />
                                {politicas.name}
                              </span>
                            );
                          })}
                        </div>
                        <DynamicDivider />
                      </section>
                    ) : (
                      ""
                    )}
                    {(node.field_in_auction !== 0 && node.field_in_auction) ||
                    (node.field_legal_impediment !== 0 &&
                      node.field_legal_impediment) ||
                    (node.field_co_ownership !== 0 &&
                      node.field_co_ownership) ||
                    (node.field_not_accepting_credits !== 0 &&
                      node.field_not_accepting_credits) ||
                    (node.field_investor !== 0 && node.field_investor) ? (
                      <section className="my-5 mx-9">
                        <div className="font-bold mb-3">
                          <Text textColor="#000000" text="Propiedad:" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 mb-3">
                          {node.field_in_auction && (
                            <span className="text-lg flex my-3 mr-4 text-base text-black">
                              <DCustomIcon
                                iconName="checkCircle"
                                viewBox="0 0 40 40"
                                className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                              />
                              Remate
                            </span>
                          )}
                          {node.field_legal_impediment && (
                            <span className="text-lg flex my-3 mr-4 text-base text-black">
                              <DCustomIcon
                                iconName="checkCircle"
                                viewBox="0 0 40 40"
                                className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                              />
                              Impedimento Legal
                            </span>
                          )}
                          {node.field_co_ownership && (
                            <span className="text-lg flex my-3 mr-4 text-base text-black">
                              <DCustomIcon
                                iconName="checkCircle"
                                viewBox="0 0 40 40"
                                className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                              />
                              Co-Propiedad
                            </span>
                          )}
                          {node.field_not_accepting_credits && (
                            <span className="text-lg flex my-3 mr-4 text-base text-black">
                              <DCustomIcon
                                iconName="checkCircle"
                                viewBox="0 0 40 40"
                                className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                              />
                              No Acepta Credito
                            </span>
                          )}
                          {node.field_investor && (
                            <span className="text-lg flex my-3 mr-4 text-base text-black">
                              <DCustomIcon
                                iconName="checkCircle"
                                viewBox="0 0 40 40"
                                className="text-green-soft mr-2 mt-1 text-[2rem] !w-10 !h-10"
                              />
                              Enfocado a Inversionistas
                            </span>
                          )}
                        </div>
                        <DynamicDivider />
                      </section>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            )}
          </InView>
          <div className="flex flex-col lg:hidden w-[90%] 2xl:w-[95%] h-96 md:h-[30rem] m-auto lg:m-0 lg:ml-auto">
            {location.lat !== 0 && location.lng !== 0 ? (
              <OsmMapNoSSR
                mostrarMapa={node.field_ocultar_ubicacion}
                center={location}
                location={location}
                draggable={true}
                title="sample text"
                onDragMaker={(e) => {
                  console.log("e", e);
                  let loc = { lat: e.lat, lng: e.lon };
                  setLocation(loc);
                }}
              />
            ) : (
              ""
            )}
            <div className="text-gray-soft mt-7 flex justify-end">
              <button onClick={handleOpen} aria-label="Abrir diálogo de eliminación de inmueble">
                <span className="border-b-2 border-slate-300 text-base">
                  Quiero eliminar este inmueble
                </span>
              </button>
              <InView triggerOnce>
                {({ inView, ref, entry }) => (
                  <div ref={ref}>
                    <div className="hidden">{/*{ staticData() }*/}</div>
                    {inView && (
                      <ModalCustom open={open} close={handleClose}>
                        <FormEliminarInmueble id_propiedad={node.id} />
                      </ModalCustom>
                    )}
                  </div>
                )}
              </InView>
            </div>
          </div>
        </section>
        {laptop ? (
          <section className="hidden lg:block h-full w-2/5">
            <div className="lg:mt-14 lg:m-auto">
              <InView triggerOnce>
                {({ inView, ref, entry }) => (
                  <div ref={ref}>
                    <div className="hidden">{/*{ staticData() }*/}</div>
                    {inView && (
                      <ContactCard
                        name={nodeIdentidades.title}
                        urlImage={nodeIdentidadesObj.image.src}
                        propertyType={node.field_property_type_id.name}
                        treatementType={tipoTrato}
                        linkDelete="/remover-inmueble-webform"
                        id_propiedad={node.id}
                        nodeId={consNum}
                        claveProp={node.field_advertisement_id}
                      />
                    )}
                  </div>
                )}
              </InView>
            </div>
            {location.lat !== 0 && location.lng !== 0 ? (
              <div className="w-full lg:w-[90%] xl:w-[80%] h-96 md:h-[30rem] lg:mr-0 mt-10 m-auto lg:ml-auto">
                <OsmMapNoSSR
                  mostrarMapa={node.field_ocultar_ubicacion}
                  center={location}
                  location={location}
                  draggable={true}
                  title="sample text"
                  onDragMaker={(e) => {
                    console.log("e", e);
                    let loc = { lat: e.lat, lng: e.lon };
                    setLocation(loc);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className="text-gray-soft mt-7 flex justify-end">
              <button onClick={handleOpen} aria-label="Abrir diálogo de eliminación de inmueble">
                <span className="border-b-2 border-slate-300 text-base">
                  Quiero eliminar este inmueble
                </span>
              </button>
              <InView triggerOnce>
                {({ inView, ref, entry }) => (
                  <div ref={ref}>
                    <div className="hidden">{/*{ staticData() }*/}</div>
                    {inView && (
                      <ModalCustom open={open} close={handleClose}>
                        <FormEliminarInmueble id_propiedad={node.id} />
                      </ModalCustom>
                    )}
                  </div>
                )}
              </InView>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
