import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { Skeleton } from '@mui/material';
import { useState, useEffect } from "react";

const DCustomIcon = dynamic(() => import("../atoms/CustomIcon"), {
  ssr: false,
});
function CardInfoInmueble({
  title,
  seoTitle,
  cost,
  address,
  bedrooms,
  areaConstruct,
  measureConst,
  areaTerrain,
  measureTerrain,
  restrooms,
  toiletNum,
  maintenance,
  parkingspaces,
  yearConst,
  treatementType,
}) {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); 
  }, []);

  return (
    <div className="bg-gray-light 2xl:h-[600px]">
      <div className="flex justify-end">
        {treatementType === "venta" ? (
          <div className="text-white font-semibold p-1.5 bg-green">VENTA</div>
        ) : (
          <div className="text-white font-semibold p-1.5 bg-red">RENTA</div>
        )}
      </div>
      <div className="text-red text-center text-5xl md:text-4xl font-bold my-3 2xl:my-7">
        {cost}
      </div>
      <div className="mx-4">
        {seoTitle ? (
          <>
            <div className="hidden">
              <h1>{seoTitle}</h1>
            </div>
            <div className="text-black font-semibold ml-[10px]">
              <h2 className="text-xl 2xl::text-4xl">{title}</h2>
            </div>
          </>
        ) : (
          <div className="text-black font-semibold ml-[10px]">
            <h1 className="text-1xl sm:text-xl 2xl:text-4xl">{title} </h1>
          </div>
        )}
      </div>
      <div className="mt-2 mb-3 2xl:mb-12 mx-4 flex items-center">
        <div className="mt-3">
          <DCustomIcon
            iconName="location"
            viewBox="0 0 50 50"
            className={"text-gray-soft text-[1.75rem]"}
          />
        </div>
        <div className="mt-3">
          <span className="text-gray-dark text-lg sm:text-base 2xl:text-xl">
            {address}
          </span>
        </div>
      </div>
      <div className="my-3 grid grid-cols-1 md:grid-cols-2">
        {bedrooms !== 0 && bedrooms ? (
          <div className="text-black m-[7px] 2xl:m-2 mr-3 flex text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light mr-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <DCustomIcon
                  iconName="hotel"
                  viewBox="0 0 1000 800"
                  className={"text-green-soft text-[2.5rem] !w-10 !h-10"}
                />
              )}
            </div>
            {isLoading ? (
              <div className="font-bold text-sm 2xl:text-base flex items-center">
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                  height={20}
                />
              </div>
            ) : (
              <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
                {bedrooms >= 2 ? (
                  <span>{bedrooms} recámaras</span>
                ) : (
                  <span>{bedrooms} recámara</span>
                )}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {areaConstruct !== 0 && areaConstruct ? (
          <div className="text-black m-[7px] 2xl:m-2 mr-3 flex text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light rounded-sm mr-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <DCustomIcon
                  iconName="ruler"
                  viewBox="0 0 1000 1000"
                  className={"text-green-soft text-[2rem] !w-10 !h-10"}
                />
              )}
            </div>
            <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                  height={20}
                />
              ) : (
                <span>
                  {areaConstruct} {measureConst} de construcción
                </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {areaTerrain !== 0 && areaTerrain ? (
          <div className="text-black m-[7px] 2xl:m-2 mr-3 flex text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light rounded-sm mr-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <DCustomIcon
                  iconName="ruler"
                  viewBox="0 0 1000 1000"
                  className={"text-green-soft text-[2rem] !w-10 !h-10"}
                />
              )}
            </div>
            <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                  height={20}
                />
              ) : (
                <span>
                  {areaTerrain} {measureTerrain} de terreno
                </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {(restrooms !== 0 && restrooms) || (toiletNum !== 0 && toiletNum) ? (
          <div className="flex text-black m-[7px] 2xl:m-2 mr-3 text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light rounded-sm mr-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <DCustomIcon
                  iconName="toilet"
                  viewBox="0 0 1000 1000"
                  className={"text-green-soft text-[2rem] !w-10 !h-10"}
                />
              )}
            </div>
            {restrooms !== 0 && restrooms ? (
              <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center mr-1">
                {restrooms >= 2 ? (
                  <>
                    {isLoading ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width={50}
                        height={20}
                      />
                    ) : (
                      <span> {restrooms} baños</span>
                    )}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width={50}
                        height={20}
                      />
                    ) : (
                      <span> {restrooms} baño</span>
                    )}
                  </>
                )}
              </div>
            ) : (
              ""
            )}
            {toiletNum !== 0 && toiletNum ? (
              <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
                {toiletNum >= 2 ? (
                  <>
                    {isLoading ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width={50}
                        height={20}
                      />
                    ) : (
                      <span>{toiletNum} medios baños</span>
                    )}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width={50}
                        height={20}
                      />
                    ) : (
                      <span>{toiletNum} medio baño</span>
                    )}
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {maintenance ? (
          <div className="text-black m-[7px] 2xl:m-2 mr-3 flex text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light rounded-sm mr-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <DCustomIcon
                  iconName="dollar"
                  viewBox="0 0 50 50"
                  className={"text-green-soft text-[2rem] !w-10 !h-10"}
                />
              )}
            </div>
            <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                  height={20}
                />
              ) : (
                <span>Mantenimiento: {maintenance} </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {parkingspaces !== 0 && parkingspaces ? (
          <div className="text-black m-[7px] 2xl:m-2 mr-3 flex text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light rounded-sm mr-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <DCustomIcon
                  iconName="car"
                  viewBox="0 0 1000 800"
                  className={"text-green-soft text-[2.5rem] !w-10 !h-10"}
                />
              )}
            </div>
            <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
              {parkingspaces >= 2 ? (
                <>
                  {isLoading ? (
                    <Skeleton
                      animation="wave"
                      varian="circle"
                      width={100}
                      height={20}
                    />
                  ) : (
                    <span>{parkingspaces} estacionamientos</span>
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <Skeleton
                      animation="wave"
                      varian="circle"
                      width={100}
                      height={20}
                    />
                  ) : (
                    <span>{parkingspaces} estacionamiento</span>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {yearConst ? (
          <div className="text-black m-[7px] 2xl:m-2 mr-3 flex text-xl md:text-2xl">
            <div className="px-3 py-0.5 bg-gray-light rounded-sm mr-3">
              <>
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    varian="circle"
                    width={40}
                    height={40}
                  />
                ) : (
                  <DCustomIcon
                    iconName="calendar"
                    viewBox="0 0 50 50"
                    className={"text-green-soft text-[2rem] !w-10 !h-10"}
                  />
                )}
              </>
            </div>
            <div className="font-bold text-base sm:text-sm 2xl:text-base flex items-center">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  varian="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <span>Año de construcción: {yearConst} </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

CardInfoInmueble.propTypes = {
  title: PropTypes.string.isRequired,
  seoTitle: PropTypes.string,
  cost: PropTypes.string,
  address: PropTypes.string,
  bedrooms: PropTypes.number,
  areaTerrain: PropTypes.number,
  measureConst: PropTypes.object,
  areaConstruct: PropTypes.number,
  measureTerrain: PropTypes.object,
  restrooms: PropTypes.number,
  toiletNum: PropTypes.number,
  maintenance: PropTypes.string,
  parkingspaces: PropTypes.number,
  yearConst: PropTypes.string,
  treatementType: PropTypes.string,
};

export default CardInfoInmueble;
